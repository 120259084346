import React, {useState} from 'react';
import "./Home.scss"

const Home = () => {
    return (
        <div class="container">
            <div className="hash">Houdini, Unreal, Unity, Procedural, Vfx, Python, C#</div>
            <div className="ml"><a href="mailto:iam@ekmelozalp.com"><span>iam</span>@ekmelozalp.com</a></div>
        </div>
    )
}


export default Home