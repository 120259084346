import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from "./Views/Home";

const App = () => (
    <Routes>
        <Route path="/" exact element={<Home />} />
    </Routes>
);

export default App;
